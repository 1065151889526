export const nineImagesStyles = {
  one: {
    marginLeft: "auto",
    display: "block",
    marginBottom: 24,
    width: "80%",
    height: "36.2%",
    // borderRadius: 3,
  },
  two: {
    width: "100%",
    height: "37%",
    // borderRadius: 3,
  },
  three: {
    width: "100%",
    height: "34.6%",
    // borderRadius: 3,
  },
  four: {
    width: "100%",
    height: "64%",
    display: "block",
    marginTop: 24,
    // borderRadius: 3,
  },
  five: {
    width: "100%",
    height: "80%",
    display: "block",
    // borderRadius: 3,
  },
  six: {
    width: "100%",
    height: "100%",
    // borderRadius: 3,
  },
  seven: {
    width: "100%",
    height: "80%",
    display: "flex",
    alignItems: "flex-end",
    borderRadius: 3,
  },
  eight: {
    width: "100%",
    height: "100%",

    borderRadius: 3,
  },
  nine: {
    width: "100%",
    height: "100%",
    borderRadius: 3,
  },
  commonLg: { width: "100%", height: "inherit" },
}
