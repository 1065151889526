import React, { useState, useRef, useEffect } from "react"
import "react-multi-carousel/lib/styles.css"
import useWindowWidth from "../../utils/useWindowWidth"
import ReactHtmlParser from "react-html-parser"
import { useSelector } from "react-redux"
import LocalizedLink from "../../components/LocalizedLink"

import {
  Button,
  Typography,
  Card,
  CardActions,
  CardContent,
  makeStyles,
} from "@material-ui/core"
import "./slider.scss"
import Img from "gatsby-image"

const useStyles = makeStyles({
  root: {
    // maxWidth: 312,
    // minHeight: 344,
    // height: "100%",
    boxShadow: "none",
  },
  media: {
    height: 140,
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#444",
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
})

const Slider = ({
  data,
  title,
  description,
  isBtn,
  isCountryIcon,
  activityCards,
}) => {
  const translations = useSelector(state => state.locale.localeObj.pageData)

  const classes = useStyles()
  const [prevDisable, setPrevDisable] = useState(true)
  const [nextDisable, setNextDisable] = useState(true)
  const [additionalTransform, setAdditionalTransform] = useState(0)
  const [gutter, setGutter] = useState(4)
  const cardRef = useRef(null)
  const windowWidth = useWindowWidth()
  const [minWidth, setMinWidth] = useState(312)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [style, setStyle] = useState({ minWidth: `${minWidth}px` })

  useEffect(() => {
    if (data.length > 4) {
    }
    if (Math.ceil(windowWidth[0] / 4) > 0) {
      if (windowWidth[0] <= 576) {
        setMinWidth((windowWidth[0] * 86.888) / 100 - 15)
        setGutter(1)
      } else if (windowWidth[0] <= 768) {
        setGutter(2)
        setMinWidth(Math.ceil(windowWidth[0] / 2) - 70)
      } else if (windowWidth[0] <= 1200) {
        setGutter(3)
        setMinWidth(Math.ceil(windowWidth[0] / 3) - 70)
      } else {
        setMinWidth(Math.ceil(windowWidth[0] / 4) - 70)
      }
    }
  }, [data.length, windowWidth])

  useEffect(() => {
    if (windowWidth[0] <= 576 && data.length > 1) {
      setNextDisable(false)
    } else if (windowWidth[0] <= 768 && data.length > 2) {
      setNextDisable(false)
    } else if (windowWidth[0] <= 1200 && data.length > 3) {
      setNextDisable(false)
    } else if (data.length > 4) {
      setNextDisable(false)
    }

    if (data.length < 4) {
      setStyle({ maxWidth: `${minWidth}px` })
    }
  }, [data, minWidth, windowWidth])
  return (
    <section className="section-carousel">
      <div className="parent-div">
        <div className="multi-carousel-main">
          <div className="heading-bar">
            <div className="top-heading">
              <Typography
                style={{
                  fontSize: "40px",
                  fontFamily: "Clearface",
                  letterSpacing: "-0.2px",
                  lineHeight: "44px",
                  paddingBottom: "10px",
                }}
              >
                {title}
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  letterSpacing: "0px",
                  fontFamily: "MabryProRegular",
                }}
              >
                {ReactHtmlParser(description)}
              </Typography>
            </div>
            <div className="carousel-button-group">
              <div
                className={prevDisable ? "disable button-one" : "button-one"}
                onClick={() => {
                  if (currentSlide <= data.length - gutter + 1) {
                    if (!prevDisable) {
                      if (currentSlide === 1) {
                        setPrevDisable(true)
                        setNextDisable(false)
                        setCurrentSlide(currentSlide => currentSlide - 1)
                        if (currentSlide === 1 && windowWidth[0] > 576) {
                          setAdditionalTransform(
                            additionalTransform =>
                              additionalTransform + minWidth - 20
                          )
                        } else {
                          setAdditionalTransform(
                            additionalTransform =>
                              additionalTransform + minWidth
                          )
                        }
                      } else {
                        setCurrentSlide(currentSlide => currentSlide - 1)
                        setNextDisable(false)
                        setAdditionalTransform(
                          additionalTransform => additionalTransform + minWidth
                        )
                      }
                    }
                  }
                }}
                style={{ opacity: prevDisable ? 0.3 : 1 }}
              >
                <div className={classes.arrow}>
                  <SliderLeftArrow width="20" height="20" />
                </div>
              </div>
              <div
                onClick={() => {
                  if (!nextDisable) {
                    if (currentSlide === data.length - gutter) {
                      setNextDisable(true)
                      setCurrentSlide(currentSlide => currentSlide + 1)

                      setAdditionalTransform(
                        additionalTransform => additionalTransform - minWidth
                      )
                    } else {
                      setPrevDisable(false)
                      setCurrentSlide(currentSlide => currentSlide + 1)
                      if (currentSlide === 0 && windowWidth[0] > 576) {
                        setAdditionalTransform(
                          additionalTransform =>
                            additionalTransform - minWidth + 20
                        )
                      } else {
                        setAdditionalTransform(
                          additionalTransform => additionalTransform - minWidth
                        )
                      }
                    }
                  }
                }}
                className="button-two"
                style={{
                  opacity: nextDisable ? 0.3 : 1,
                }}
              >
                <div className={classes.arrow}>
                  <SliderRightArrow width="20" height="20" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="carousel-sub-main"
            style={{ transform: `translateX(${additionalTransform}px)` }}
          >
            {data.map(item => {
              return (
                <LocalizedLink
                  style={{ textDecoration: "none" }}
                  to={`/${item?.node?.slug}`}
                >
                  <div className="each-item" ref={cardRef} style={style}>
                    <Card className={classes.root}>
                      {!isBtn ? (
                        <div className="flip-card">
                          <div className="flip-card-inner">
                            <div className="flip-card-front">
                              {!!item?.activityImage?.fluid && (
                                <Img
                                  style={{ height: "174px" }}
                                  component="img"
                                  alt={item?.activityImage?.title}
                                  fluid={item?.activityImage?.fluid}
                                />
                              )}
                            </div>
                            <div className="flip-card-back">
                              <p>
                                {ReactHtmlParser(
                                  item.mainDescription?.childMarkdownRemark?.html
                                    ?.split("\n")
                                    ?.join("<br/>")
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        item?.node?.bannerImage?.fluid && (
                          <Img
                            style={{ height: "174px" }}
                            alt="Contemplative Reptile"
                            fluid={item?.node?.bannerImage?.fluid}
                          />
                        )
                      )}
                      <CardContent style={{ padding: 25, paddingBottom: 20 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {isCountryIcon && (
                            <Img
                              style={{
                                width: "16px",
                                height: "16px",
                                borderRadius: 30,
                                marginRight: 5,
                                marginBottom: 9,
                              }}
                              fluid={item?.node?.countryFlag?.fluid}
                            />
                          )}
                          <Typography
                            gutterBottom
                            style={{
                              fontFamily: "MabryProRegular",
                              fontSize: "20px",
                              textTransform: "capitalize",
                            }}
                          >
                            {isBtn ? item?.node?.cityName : item.activityName}
                          </Typography>
                        </div>
                        <Typography
                          style={{
                            fontFamily: "MabryProLight",
                            fontSize: "16px",
                            overflow: "hidden",
                            display: "-webkit-box",
                            webkitLineClamp: "2",
                            webkitBoxOrient: "vertical",
                            textTransform: "capitalize",
                            color: "black",
                            marginTop: -2,
                            height: 50,
                          }}
                        >
                          {isBtn
                            ? ReactHtmlParser(
                                item?.node?.cardTruncateDescription
                                  ?.cardTruncateDescription
                              )
                            : item.activityDescription.activityDescription}
                        </Typography>
                      </CardContent>
                      <CardActions
                        style={{
                          paddingLeft: 24,
                          paddingBottom: 24,
                          paddingTop: 0,
                        }}
                      >
                        {isBtn && (
                          <Button
                            style={{
                              fontFamily: "MabryProRegular",
                              fontSize: "16px",
                              textTransform: "initial",
                              letterSpacing: "0px",
                            }}
                            variant="outlined"
                            endIcon={
                              <div className={classes.arrow}>
                                <SliderRightArrow width="20" height="20" />
                              </div>
                            }
                            // href={item.dmcpdf}
                            size="small"
                          >
                            {translations?.learnMore}
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </div>
                </LocalizedLink>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

const SliderLeftArrow = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    <path d="M501.333 245.333H36.417l141.792-141.792c4.167-4.167 4.167-10.917 0-15.083-4.167-4.167-10.917-4.167-15.083 0l-160 160c-4.167 4.167-4.167 10.917 0 15.083l160 160a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125 4.167-4.167 4.167-10.917 0-15.083L36.417 266.667h464.917c5.896 0 10.667-4.771 10.667-10.667s-4.772-10.667-10.668-10.667z"></path>
  </svg>
)

export const SliderRightArrow = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={width}
      height={height}
    >
      <path d="M508.875 248.458l-160-160c-4.167-4.167-10.917-4.167-15.083 0-4.167 4.167-4.167 10.917 0 15.083l141.792 141.792H10.667C4.771 245.333 0 250.104 0 256s4.771 10.667 10.667 10.667h464.917L333.792 408.458c-4.167 4.167-4.167 10.917 0 15.083a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125l160-160c4.166-4.166 4.166-10.916-.001-15.083z"></path>
    </svg>
  )
}

export default Slider
