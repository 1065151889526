import React from "react"
import { Grid, Box, withWidth } from "@material-ui/core"
import { nineImagesStyles } from "./styles"
import Img from "gatsby-image"
import "./index.scss"

const NineImages = props => {
  const { images, width } = props

  const isSmall = width === "xs" || width === "sm"
  return (
    <main>
      <Grid
        container
        spacing={3}
        justify={isSmall ? "center" : ""}
        className="nine-images-grid"
      >
        <Grid item xs={2} className="first-grid">
          <Img
            fluid={images[0].fluid}
            style={nineImagesStyles.one}
            className="first-image"
          />
          <Img fluid={images[1].fluid} style={nineImagesStyles.two} />
        </Grid>
        <Grid item xs={3} className="second-grid">
          <Img fluid={images[2].fluid} style={nineImagesStyles.three} />
          <Img
            fluid={images[3].fluid}
            style={nineImagesStyles.four}
            className="fourth-image"
          />
        </Grid>
        <Grid item xs={2} className="third-grid">
          <Img fluid={images[4].fluid} style={nineImagesStyles.five} />
        </Grid>
        <Grid item xs={4} className="fourth-grid">
          <Box className="fourth-sub-grid">
            <div className="last">
              <div style={nineImagesStyles.six}>
                <Img
                  fluid={images[5].fluid}
                  style={nineImagesStyles.commonLg}
                />
              </div>
              <div style={nineImagesStyles.seven}>
                <Img
                  style={nineImagesStyles.commonLg}
                  fluid={images[6].fluid}
                />
              </div>
            </div>
            <div className="second-last">
              <div style={nineImagesStyles.eight}>
                <Img
                  style={nineImagesStyles.commonLg}
                  fluid={images[7].fluid}
                />
              </div>
              <div style={nineImagesStyles.nine}>
                <Img
                  style={nineImagesStyles.commonLg}
                  fluid={images[8].fluid}
                />
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </main>
  )
}

export default withWidth()(NineImages)
