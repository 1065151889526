import { withWidth } from "@material-ui/core"
import CardMedia from "@material-ui/core/CardMedia"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CircleChecked from "@material-ui/icons/CheckCircleOutline"
import Axios from "axios"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Footer from "../components/Footer"
import { ImageSliderWithText } from "../components/ImageSliderWithText"
import Layout from "../components/Layout"
import NineImages from "../components/NineImages"
import Slider from "../components/slider"
import Textfield from "../components/TextField"
import { apiUrl } from "../config/api"
import DMCAirport from "../images/Airport.png"
import attraction from "../images/attraction.png"
import Close from "../images/Close.png"
import DMCHotels from "../images/DMCHotels.png"
import DMCRestaurant from "../images/DMCRestaurant.png"
import HelloDmc from "../images/HelloDmc.png"
import population from "../images/population.png"
import Recomended from "../images/Recomended.png"
import Sunnydays from "../images/Sunnydays.png"
import { SEO } from "../components/SEO"

const useStyles = makeStyles(theme => ({
  media: {
    height: 376,
  },
  image: {
    height: 500,
    width: "100%",
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: "block",
    width: "100%",
  },
}))

const AboutCity = props => {
  const classes = useStyles()
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const { width } = props
  const isSmall = width === "xs" || width === "sm"
  const [data, setData] = useState({})
  const [similarDestinations, setsimilarDestinations] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true)
  const { location } = props
  let splittedArray = location.pathname.split("/")
  let id = splittedArray[splittedArray.length - 1]
  useEffect(() => {
    Axios.get(`${apiUrl}/cities/${id}`)
      .then(res => {
        setData(res.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    Axios.get(`${apiUrl}/dmcs?category=Destinations`).then(res => {
      setsimilarDestinations(res.data.filter(item => item.cityid !== id))
    })
  }, [id])

  return (
    <Layout location={props.location} pageContext={props.pageContext}>
      <SEO/>
      <Grid
        container
        spacing={2}
        justify="center"
        style={isSmall ? {} : { paddingRight: 58, paddingLeft: 58 }}
        className={locale === "zh" && "chinese-version"}
      >
        <Grid item xs={10} md={12} lg={12}>
          <CardMedia className={classes.media} image={data.img1}></CardMedia>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justify="center"
        style={{
          paddingTop: 60,
          paddingBottom: 60,
        }}
      >
        <Grid item lg={5} md={6} xs={10}>
          <img alt=""
            src={data.logo}
            style={{ width: 20, height: 20, borderRadius: 10 }}
          />
          <Typography style={{ fontSize: 40, fontFamily: "ClearfaceRegular" }}>
            {data.name}
          </Typography>
          <Typography
            style={{
              fontSize: 20,
              fontFamily: "MabryProLight",
            }}
          >
            {data.detail}
          </Typography>
        </Grid>
      </Grid>
      {data.img2 && <NineImages images={data.img2} />}
      <Grid container justify="center" style={{ paddingLeft: 20 }}>
        <Grid item sm={9} md={7} lg={5}>
          <Typography
            style={{
              fontFamily: "clearfaceRegular",
              fontSize: 40,
              marginTop: 120,
            }}
          >
            About the city
          </Typography>
          <Typography
            style={{
              marginBottom: 30,
              fontFamily: "MabryProLight",
              fontSize: 20,
            }}
          >
            {data.about}
          </Typography>
          <Grid
            container
            spacing={2}
            justify="center"
            style={{ paddingTop: 30, paddingBottom: 30 }}
          >
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={population}
              />
              <Typography style={{ fontSize: 16, fontFamily: "MabryProBold" }}>
                Population
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                Approx. {data.metrix && data.metrix.population}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={DMCHotels}
              />
              <Typography style={{ fontFamily: "MabryProBold", fontSize: 16 }}>
                {data.metrix && data.metrix.hotel && data.metrix.hotel.count}{" "}
                Hotels
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                More than{" "}
                {data.metrix && data.metrix.hotel && data.metrix.hotel.bedCount}{" "}
                beds
              </Typography>
            </Grid>
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={DMCAirport}
              />
              <Typography style={{ fontFamily: "MabryProBold", fontSize: 16 }}>
                Airport
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                {data.metrix && data.metrix.airport}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            spacing={3}
            style={{ paddingTop: 30, paddingBottom: 30 }}
          >
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={Close}
              />
              <Typography style={{ fontFamily: "MabryProBold", fontSize: 16 }}>
                Close by
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                {data.metrix && data.metrix.closeBy}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={attraction}
              />
              <Typography style={{ fontFamily: "MabryProBold", fontSize: 16 }}>
                Attractions
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                {data.metrix && data.metrix.attractions}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={Recomended}
              />
              <Typography style={{ fontFamily: "MabryProBold", fontSize: 16 }}>
                Reccomened for
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                {data.metrix && data.metrix.recommendedFor}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            spacing={2}
            style={{ paddingTop: 30, paddingBottom: 30 }}
          >
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={DMCRestaurant}
              />
              <Typography style={{ fontFamily: "MabryProBold", fontSize: 16 }}>
                Popular Activities
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                {data.metrix && data.metrix.activities}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={10}>
              <img alt=""
                style={{ width: 40, height: 30, marginRight: 5 }}
                src={Sunnydays}
              />
              <Typography style={{ fontFamily: "MabryProBold", fontSize: 16 }}>
                Sunny days / year
              </Typography>
              <Typography style={{ fontSize: 16, fontFamily: "MabryProLight" }}>
                {data.metrix && data.metrix.sunnyDays} days
              </Typography>
            </Grid>
            <Grid item sm={4} xs={10}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        justify="center"
        style={{ paddingTop: 60, paddingLeft: 20 }}
      >
        <Grid item sm={9} md={7} lg={5}>
          <Typography
            variant="h4"
            component="h4"
            style={{ fontFamily: "Clearface Regular", fontSize: 40 }}
          >
            Popular activities
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 80,
              flexWrap: "wrap",
            }}
          >
            {data.popularActivities &&
              data.popularActivities.split(",").map(item => (
                <>
                  <CircleChecked />

                  <Typography
                    style={{
                      fontSize: 16,
                      marginLeft: 10,
                      marginRight: 20,
                      fontFamily: "MabryProLight",
                    }}
                  >
                    {item}
                  </Typography>
                </>
              ))}
          </div>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={11} md={9} lg={7}>
          <ImageSliderWithText
            data={data.photos}
            style={{ marginBottom: isSmall ? 60 : 120 }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Slider
        title={"Similar destinations"}
        description={
          "Creating value & adding destinations meaning of chinese living in or visiting Portugal"
        }
        button={"Learn More"}
        data={similarDestinations}
        isBtn={true}
        isCountryIcon={true}
      />
      <Divider />
      <Textfield backgroundColor={"black"} image={HelloDmc} />
      <Grid container justify="center">
        <Grid xs={11} sm={10} item>
          <Footer />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default withWidth()(AboutCity)
